import Image from "next/image";
import { useTranslation } from "next-i18next";
import { bool, func, number, shape, string } from "prop-types";

import Button from "~components/Button";
import Icon from "~components/Icon";
import classnames from "~utils/classnames";

import classes from "./VideoCard.module.scss";

const getButtonTranslation = (t, isActive, isPlaying) => {
  if (!isActive) {
    return { buttonLabel: t("playVideo"), iconType: "play" };
  }
  if (isPlaying) {
    return { buttonLabel: t("pauseVideo"), iconType: "pause" };
  }
  return { buttonLabel: t("activeVideo"), iconType: "play" };
};

const VideoCard = ({ video, isActive = false, isPlaying = false, onClick }) => {
  const { t } = useTranslation();

  const { buttonLabel, iconType } = getButtonTranslation(
    t,
    isActive,
    isPlaying,
  );

  return (
    <div
      className={classnames(classes.videoThumb, isActive && classes.active)}
      onClick={() => onClick?.(video)}
    >
      <div className={classes.overlay} />
      <div className={classes.overlayContent}>
        <Button className={classes.playButton} onClick={() => onClick?.(video)}>
          <Icon type={iconType} />
          {buttonLabel}
        </Button>
      </div>
      <Image
        className={classes.thumbnail}
        src={`https://img.youtube.com/vi/${video.youtubeID}/sddefault.jpg`}
        width={390}
        height={180}
        alt={`Thumbnail of ${video.title}`}
      />
    </div>
  );
};

VideoCard.propTypes = {
  video: shape({
    id: number.isRequired,
    title: string.isRequired,
    youtubeID: string.isRequired,
  }).isRequired,
  isActive: bool,
  isPlaying: bool,
  onClick: func.isRequired,
};

export default VideoCard;
