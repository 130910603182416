import { useRouter } from "next/router";
import { arrayOf, oneOfType, shape, string } from "prop-types";
import useSWR from "swr/immutable";

import localeStringConverter from "~common/localeStringConverter";
import LocationsAndEvents from "~components/LocationsAndEvents";
import SpinningLoader from "~components/SpinningLoader";
import { PreprDataType } from "~types";

const emptyArray = [];

const DynamicEventsAndLocationsBlock = ({
  title,
  titleEmphasis,
  subTitle,
  ctaLabel,
  ctaSlug,
  categories = emptyArray,
  tags = emptyArray,
  preprData,
}) => {
  const { locale: lang } = useRouter();
  const categoryIds = categories?.map(({ id }) => id) ?? [];
  const locale = localeStringConverter.toLocale(lang);

  // TODO: This shouldn't be working because URLSearchParams expects a string for categories,
  // yet it currently is receiving an array
  const urlSearchParams = new URLSearchParams({
    locale,
    categories: categoryIds,
    tags,
    limit: "10",
  });

  const { data, isLoading } = useSWR(
    `/api/upcoming-events?${urlSearchParams.toString()}`,
    async (slug) => {
      const response = await fetch(slug);
      const result = await response.json();
      const { body } = result;

      // Sort Events before Locations
      return body;
    },
  );

  return isLoading ? (
    <SpinningLoader size="small" />
  ) : (
    <LocationsAndEvents
      title={title}
      titleEmphasis={titleEmphasis}
      subTitle={subTitle}
      ctaLabel={ctaLabel}
      ctaSlug={`/${ctaSlug}`}
      items={data}
      preprData={preprData}
    />
  );
};

export const DynamicEventsAndLocationsBlockType = {
  id: string,
  title: string,
  titleEmphasis: string,
  subTitle: string,
  ctaLabel: string,
  ctaSlug: string,
  categories: arrayOf(
    shape({
      id: string.isRequired,
      slug: string.isRequired,
      title: string,
    }),
  ),
  tags: oneOfType([arrayOf(string), string]),
  preprData: shape(PreprDataType),
};

DynamicEventsAndLocationsBlock.propTypes = DynamicEventsAndLocationsBlockType;

export const DynamicEventsAndLocationsFragment = /* GraphQL */ `
  fragment DynamicEventsAndLocationsFragment on UpcomingEventsBlock {
    id: _id
    type: __typename
    title
    titleEmphasis: title_emphasis
    ctaLabel: cta_label
    ctaSlug: cta_slug
    categories {
      id: _id
      slug: _slug
      stitle: title
    }
    tags
  }
`;

export default DynamicEventsAndLocationsBlock;
