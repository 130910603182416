import { useRouter } from "next/router";
import { arrayOf, oneOfType, shape, string } from "prop-types";
import useSWR from "swr/immutable";

import localeStringConverter from "~common/localeStringConverter";
import { EventCardType } from "~components/Cards/EventCard";
import { LocationCardType } from "~components/Cards/LocationCard";
import LocationsAndEventsGrid from "~components/LocationsAndEventsGrid";
import SpinningLoader from "~components/SpinningLoader";
import { PreprDataType } from "~types";

const emptyArray = [];

const EventsAndLocationsGridBlock = ({
  title,
  titleEmphasis,
  description,
  ctaLabel,
  ctaSlug,
  category = emptyArray,
  tags = emptyArray,
  items,
  preprData,
}) => {
  const { locale: lang } = useRouter();
  const categoryIds = category?.map(({ id }) => id) ?? [];
  const locale = localeStringConverter.toLocale(lang);

  // Create a URLSearchParams instance if categories are provided
  const urlSearchParams = new URLSearchParams({
    locale,
    categories: categoryIds.join(","),
    tags,
    limit: "24",
  });

  const shouldFetch = category.length > 0;

  const { data, isLoading } = useSWR(
    shouldFetch ? `/api/upcoming-events?${urlSearchParams.toString()}` : null,
    async (slug) => {
      const response = await fetch(slug);
      const { body } = await response.json();
      return body;
    },
  );

  const finalItems = data || items;

  return shouldFetch && isLoading ? (
    <SpinningLoader size="small" />
  ) : (
    <LocationsAndEventsGrid
      title={title}
      titleEmphasis={titleEmphasis}
      description={description}
      ctaLabel={ctaLabel}
      ctaSlug={`/${ctaSlug}`}
      items={finalItems}
      preprData={preprData}
    />
  );
};

export const EventsAndLocationsGridBlockType = {
  id: string,
  title: string,
  titleEmphasis: string,
  description: string,
  ctaLabel: string,
  ctaSlug: string,
  items: arrayOf(oneOfType([shape(EventCardType), shape(LocationCardType)])),
  category: arrayOf(
    shape({
      id: string.isRequired,
      slug: string.isRequired,
      title: string,
    }),
  ),
  tags: oneOfType([arrayOf(string), string]),
  preprData: shape(PreprDataType),
};

EventsAndLocationsGridBlock.propTypes = EventsAndLocationsGridBlockType;

export const EventsAndLocationsGridBlockFragment = /* GraphQL */ `
  fragment EventsAndLocationsGridBlockFragment on EventAndLocationGrid {
    id: _id
    type: __typename
    title
    titleEmphasis: title_emphasis
    description
    ctaLabel: cta_label
    ctaSlug: cta_slug
    category {
      id: _id
      slug: _slug
      stitle: title
    }
    items {
      ...EventCardFragment
      ...LocationCardFragment
    }
  }
`;

export default EventsAndLocationsGridBlock;
