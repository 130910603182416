import { arrayOf, shape, string } from "prop-types";

import Card from "~components/Cards";
import ItemRow from "~components/ItemRow";
import { ImageType } from "~types";

// Helper function for extracting image
const getImage = (heroImage, heroImageUrl) => {
  if (heroImage?.[0]) {
    return heroImage[0];
  }
  if (heroImageUrl) {
    return {
      alt: "",
      src: heroImageUrl,
    };
  }
  return null;
};

// Functional component for rendering top tasks
export const TopTasks = ({ tasks }) => (
  <ItemRow>
    {tasks.map(({ id, heroImage, heroImageUrl, slug, title }) => {
      const image = getImage(heroImage, heroImageUrl);
      return (
        <Card
          key={id}
          id={id}
          slug={`/${slug}`}
          title={title}
          image={image}
          type="link"
        />
      );
    })}
  </ItemRow>
);

export const TopTasksType = arrayOf(
  shape({
    id: string,
    heroImageUrl: string,
    heroImage: arrayOf(shape(ImageType)),
    slug: string,
    title: string,
  }),
);

TopTasks.propTypes = {
  tasks: TopTasksType,
};
