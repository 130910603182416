import Image from "next/image";
import Script from "next/script";
import { useTranslation } from "next-i18next";
import { bool, func, string } from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";

import Icon from "~components/Icon";
import classnames from "~utils/classnames";

import classes from "./YoutubeAPIEmbed.module.scss";

const YoutubeAPIEmbed = ({
  title,
  embedId,
  forcePaused = false,
  onPlayerStateChange,
  className,
}) => {
  const [showVideoElement, setShowVideoElement] = useState(false);
  const playerRef = useRef(null);
  const playerElementRef = useRef(null);
  const { t } = useTranslation();

  // Update player state change handler ref
  const onPlayerStateChangeRef = useRef(onPlayerStateChange);
  useEffect(() => {
    onPlayerStateChangeRef.current = onPlayerStateChange;
  }, [onPlayerStateChange]);

  // Pause video when forcePaused is true
  useEffect(() => {
    if (forcePaused && playerRef.current) {
      playerRef.current.pauseVideo();
    }
  }, [forcePaused]);

  const initializePlayer = useCallback(() => {
    if (!window?.YT || !playerElementRef.current) {
      return;
    }

    if (playerRef.current) {
      playerRef.current.destroy();
    }

    playerRef.current = new window.YT.Player(playerElementRef.current, {
      width: "860",
      height: "483",
      videoId: embedId,
      playerVars: {
        playsinline: 1,
        controls: 1,
        disablekb: 1,
        rel: 0,
        showinfo: 0,
      },
      events: {
        onStateChange: (event) => onPlayerStateChangeRef.current?.(event),
      },
    });
  }, [embedId]);

  // Initialize player when video element is shown
  useEffect(() => {
    if (!showVideoElement) return;

    if (window.YT) {
      initializePlayer();
    } else {
      window.onYouTubeIframeAPIReady = initializePlayer;
    }

    return () => {
      playerRef.current?.destroy();
    };
  }, [showVideoElement, initializePlayer]);

  return (
    <div className={classnames(classes.videoResponsive, className)}>
      <Script
        src="https://www.youtube.com/iframe_api"
        strategy="afterInteractive"
      />
      {showVideoElement ? (
        <div ref={playerElementRef} id={`YT-player-${embedId}`} />
      ) : (
        <div className={classes.videoThumb}>
          <div className={classes.overlay} />
          <div className={classes.overlayContent}>
            {title && <h2 className={classes.title}>{title}</h2>}
            <button
              onClick={() => setShowVideoElement(true)}
              className={classes.playButton}
            >
              <Icon type="play" />
              {t("playVideo")}
            </button>
          </div>
          <Image
            className={classes.thumbnail}
            src={`https://img.youtube.com/vi/${embedId}/sddefault.jpg`}
            width={860}
            height={483}
            alt="Video thumbnail"
          />
        </div>
      )}
    </div>
  );
};

export const YoutubeEmbedFragment = /* GraphQL */ `
  fragment YoutubeEmbedFragment on YouTube {
    id: _id
    type: __typename
    embedId: youtube_id
    title
  }
`;

export const YoutubeAPIEmbedType = {
  title: string,
  embedId: string.isRequired,
  onPlayerStateChange: func,
  forcePaused: bool,
  className: string,
};
YoutubeAPIEmbed.propTypes = YoutubeAPIEmbedType;

export default YoutubeAPIEmbed;
