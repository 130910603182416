import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import useSWR from "swr/immutable";

import LinkCard, { cardImageSizes } from "~components/Cards/LinkCard";
import Select from "~components/forms/Select";
import Pagination from "~components/Pagination";
import SpinningLoader from "~components/SpinningLoader";
import { locales } from "~config/locale";
import cssVariables from "~styles/export.module.scss";
import formatDate from "~utils/formatDate";

import classes from "./TaggedArticleBlock.module.scss";

const MOBILE_PAGE_SIZE = 6;
const DESKTOP_PAGE_SIZE = 9;

const fetchArticles = async ({
  sort,
  locale,
  tags = [],
  parentId = null,
  limit = 9,
  articleType = "all",
}) => {
  const urlSearchParams = new URLSearchParams({
    sort: sort || "published",
    locale,
    limit: String(limit),
    articleType,
  });
  if (parentId) {
    // only get child articles when no tags are selected
    urlSearchParams.append("parentId", parentId);
  }
  tags.forEach((tag) => urlSearchParams.append("tags", tag));
  const res = await fetch(`/api/articles?${urlSearchParams.toString()}`);
  if (res.status === 200) {
    return res.json();
  }
  throw new Error("Failed to fetch articles");
};

const emptyArray = [];

const TaggedArticleBlock = ({
  title,
  parentId,
  tags: rawTags = emptyArray,
  index,
  articleType,
}) => {
  const elementId = `articles-${index + 1}`;
  const elRef = useRef(null);
  const { locale } = useRouter();
  const { t } = useTranslation();
  const tags = typeof rawTags === "string" ? [rawTags] : rawTags;
  const [pageSize, setPageSize] = useState(null);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("published");
  const shouldFetchArticles = !!pageSize;
  // when tags is set, we don't want to filter the articles on parent id anymore
  const articlesParentId = tags?.length ? null : parentId;

  const validArticleType = articleType || "all";

  const {
    isLoading,
    data: articles,
    error: errorLoadingArticles,
  } = useSWR(
    shouldFetchArticles
      ? `articles/${locale}/${parentId}/${tags.join(",")}/${sort}/${pageSize}/${validArticleType}`
      : null,
    () =>
      fetchArticles({
        parentId: articlesParentId,
        tags,
        sort,
        locale,
        limit: Math.min(10 * pageSize, 100),
        articleType: validArticleType,
      }),
  );
  const totalPages = Math.ceil((articles || []).length / pageSize);

  const pageArticles = useMemo(
    () => (articles || []).slice((page - 1) * pageSize, page * pageSize),
    [articles, page, pageSize],
  );

  const onSortChange = (event) => {
    setSort(event.target.value);
    setPage(1);
  };

  const onGoToPage = (newPage) => {
    if (page !== newPage) {
      setPage(newPage);
      if (elRef.current) {
        elRef.current.scrollIntoView();
      }
    }
  };

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    const onResize = () => {
      if (window.innerWidth < parseInt(cssVariables.breakpointLG, 10)) {
        setPageSize(MOBILE_PAGE_SIZE);
      } else {
        setPageSize(DESKTOP_PAGE_SIZE);
      }
    };
    onResize();

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  if (errorLoadingArticles) {
    return null;
  }

  return (
    <section ref={elRef} id={elementId}>
      <div className={classes.header}>
        <h2>{title}</h2>
        <div className={classes.sortInput}>
          <label htmlFor={`filter-page-sort-${elementId}`}>
            {t("sortBy")}:
          </label>
          <Select
            id={`filter-page-sort-${elementId}`}
            name="sort"
            label={t("sortResultsOn")}
            hideLabel
            variant="light"
            options={[
              { label: t("published"), value: "published" },
              { label: t("popular"), value: "popular" },
            ]}
            value={sort}
            width="auto"
            onChange={onSortChange}
          />
        </div>
      </div>

      <div className={classes.content}>
        {isLoading && (
          <div
            className={classes.loader}
            style={{ minHeight: `${cardImageSizes.small.height}px` }}
          >
            <SpinningLoader size="medium" />
          </div>
        )}
        {!isLoading && pageArticles?.length ? (
          <div className={classes.grid}>
            {pageArticles.map((article) => (
              <LinkCard
                key={article.id}
                size="small"
                title={article.title}
                slug={article.slug}
                image={article.image}
                imageSource={article.imageSource}
                top={
                  article.publishOn &&
                  formatDate(article.publishOn, locale, "PPP")
                }
                bottom={article.author?.fullName}
              />
            ))}
          </div>
        ) : null}
        {!errorLoadingArticles && !isLoading && pageArticles?.length === 0 && (
          <div className={classes.empty}>
            <strong>{t("noArticlesFound")}</strong>
          </div>
        )}
      </div>
      {totalPages > 1 && (
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          onPageChange={onGoToPage}
        />
      )}
    </section>
  );
};

export const TaggedArticleBlockType = {
  locale: PropTypes.oneOf(locales).isRequired,
  title: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  index: PropTypes.number.isRequired,
  articleType: PropTypes.oneOf(["all", "articles", "newsArticles"]),
};

TaggedArticleBlock.propTypes = TaggedArticleBlockType;

export const TaggedArticleBlockFragment = /* GraphQL */ `
  fragment TaggedArticleBlockFragment on TaggedArticleBlock {
    id: _id
    type: __typename
    title
    tags
    articleType: article_type
  }
`;

export default TaggedArticleBlock;
