import { oneOf, string } from "prop-types";

import ImageAssetFragment from "~fragments/ImageAssetFragment";

import { TopTasks, TopTasksType } from "./TopTasks";
import classes from "./TopTasksBlock.module.scss";

// Helper function to filter tasks
const filterTasks = (tasks) =>
  tasks?.filter((task) => task.slug && (task.heroImage || task.heroImageUrl)) ||
  [];

const TopTasksBlock = ({ title, topTasks, topTasksSecond }) => {
  const filteredTopTasks = filterTasks(topTasks);
  const filteredTopTasksSecond = filterTasks(topTasksSecond);

  if (filteredTopTasks.length === 0) {
    return null;
  }

  return (
    <>
      {title && <h2 className={classes.title}>{title}</h2>}
      <div className={classes.tasks}>
        <TopTasks tasks={filteredTopTasks} />
        {filteredTopTasksSecond.length > 0 && (
          <TopTasks tasks={filteredTopTasksSecond} />
        )}
      </div>
    </>
  );
};

export const TopTasksBlockType = {
  id: string,
  type: oneOf(["TopTasks", "ArticleOverviewBlock", "FeaturedBlock"]),
  title: string,
  topTasks: TopTasksType.isRequired,
  topTasksSecond: TopTasksType,
};

TopTasksBlock.propTypes = TopTasksBlockType;

const TopTasksFragmentPages = /* GraphQL */ `
  ... on OverviewPage {
    id: _id
    slug: _slug
    title
    heroImage: hero_image {
      ${ImageAssetFragment(768, 436)}
    }
  }
  ... on VenueFinderPage {
    id: _id
    slug: _slug
    title
    heroImage: hero_image {
      ${ImageAssetFragment(768, 436)}
    }
  }
  ... on Directory {
    id: _id
    slug: _slug
    title
    heroImage: hero_image {
      ${ImageAssetFragment(768, 436)}
    }
  }
  ... on ProductSelectorPage {
    id: _id
    slug: _slug
    title
    heroImage: hero_image {
      ${ImageAssetFragment(768, 436)}
    }
  }
`;

export const TopTasksBlockFragment = /* GraphQL */ `
  fragment TopTasksBlockFragment on TopTasks {
    id: _id
    type: __typename
    title
    topTasks: top_tasks {
      ${TopTasksFragmentPages}
    }
    topTasksSecond: top_tasks_second {
      ${TopTasksFragmentPages}
    }
  }
`;

export default TopTasksBlock;
