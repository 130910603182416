import { useTranslation } from "next-i18next";
import { arrayOf, shape, string } from "prop-types";
import { useRef, useState } from "react";

import VideoCard from "~components/Cards/VideoCard";
import Carousel from "~components/Carousel";
import IconButton from "~components/IconButton";
import YoutubeAPIEmbed from "~components/YoutubeAPIEmbed";
import useBreakpoint from "~hooks/useBreakpoint";
import classnames from "~utils/classnames";

import classes from "./YoutubeCarousel.module.scss";

const CONFIG_CAROUSEL = {
  900: {
    slidesPerView: 3,
    width: null,
  },
};

const YoutubeCarousel = ({ title, description, videos }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const { t } = useTranslation();
  const { isUpToMD } = useBreakpoint();

  const [videoState, setVideoState] = useState({
    activeIndex: 0, // Initial index set to 0, pointing to the first video
    isPlaying: false, // Initially not playing
  });

  const handleVideoClick = (index) => {
    setVideoState({
      activeIndex: index,
      isPlaying: false,
    });
  };

  const handleActiveIndexChange = (index) => {
    setVideoState({
      activeIndex: index,
      isPlaying: false,
    });
  };

  const selectedVideo = videos[videoState.activeIndex];

  return (
    <section className={classes.youtubeCarousel}>
      <div className={classes.content}>
        {title && <h2 className={classes.title}>{title}</h2>}
        {description && <p className={classes.description}>{description}</p>}
      </div>
      <div className={classes.youtube}>
        {selectedVideo && !isUpToMD() && (
          <div className={classes.largePlayer}>
            <YoutubeAPIEmbed
              title={selectedVideo.title}
              embedId={selectedVideo.youtubeID}
              className={classes.largeVideo}
            />
          </div>
        )}
        <div className={classes.videoContainer}>
          {/* {videos.length >= 3 ? ( */}
          <div className={classes.carousel}>
            <Carousel
              showNavigation
              prevButtonRef={prevRef}
              nextButtonRef={nextRef}
              spaceBetween={16}
              width={330}
              breakpoints={CONFIG_CAROUSEL}
              onActiveIndexChange={handleActiveIndexChange}
              className={classes.slider}
              scrollBar={{
                horizontalClass: classes.scrollBar,
                dragClass: classes.scrollBarDrag,
              }}
            >
              {videos.map((video, index) =>
                !isUpToMD() ? (
                  <VideoCard
                    key={video.id}
                    video={video}
                    isActive={videoState.activeIndex === index}
                    isPlaying={videoState.isPlaying}
                    onClick={() => handleVideoClick(index)}
                  />
                ) : (
                  <YoutubeAPIEmbed
                    key={video.id}
                    title={video.title}
                    embedId={video.youtubeID}
                  />
                ),
              )}
            </Carousel>
            <div className={classes.counter}>
              {videoState.activeIndex + 1}/{videos.length}
            </div>
            <div className={classes.controls}>
              <IconButton
                icon="arrowLeftThin"
                title={t("previous")}
                color="tertiary"
                ref={prevRef}
                className={classnames(classes.prevButton)}
              />
              <IconButton
                icon="arrowRightThin"
                title={t("next")}
                color="tertiary"
                ref={nextRef}
                className={classnames(classes.nextButton)}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

YoutubeCarousel.propTypes = {
  title: string.isRequired,
  description: string,
  videos: arrayOf(shape({ id: string, title: string, youtubeID: string }))
    .isRequired,
};

export const YoutubeCarouselFragment = /* GraphQL */ `
  fragment YoutubeCarouselFragment on YoutubeCarousel {
    id: _id
    type: __typename
    title
    description
    videos {
      id: _id
      title
      hlink
      youtubeID: youtube_id
    }
  }
`;

export default YoutubeCarousel;
