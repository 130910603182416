import { useTranslation } from "next-i18next";
import { arrayOf, shape, string } from "prop-types";
import { useEffect, useRef, useState } from "react";

import IconButton from "~components/IconButton";
import ImageComponent from "~components/ImageComponent";
import { ImageType } from "~types";
import classnames from "~utils/classnames";
import { clamp } from "~utils/utils";

import classes from "./ImageCarousel.module.scss";

const emptyArray = [];

const ImageCarousel = ({ images = emptyArray, imageAltFallback }) => {
  const { t } = useTranslation();
  const carouselRef = useRef(null);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleScroll = (slide) => {
    const carouselElement = carouselRef.current;
    if (!carouselElement) {
      return;
    }

    carouselElement.scrollTo({
      top: 0,
      left: slide * carouselWidth,
      behaviour: "smooth",
    });
  };

  const prev = () => {
    if (currentSlide === 0) {
      return;
    }
    handleScroll(currentSlide - 1);
  };

  const next = () => {
    if (currentSlide === images.length - 1) {
      return;
    }
    handleScroll(currentSlide + 1);
  };

  useEffect(() => {
    const carouselElement = carouselRef.current;
    if (!carouselElement) {
      return;
    }

    setCarouselWidth(carouselElement.getBoundingClientRect().width);

    const onScroll = (event) => {
      setCurrentSlide(
        clamp(
          Math.round(event.target.scrollLeft / carouselWidth),
          0,
          images.length - 1,
        ),
      );
    };

    carouselElement.addEventListener("scroll", onScroll);

    return () => {
      if (carouselElement) {
        carouselElement.removeEventListener("scroll", onScroll);
      }
    };
  }, [carouselWidth, images.length]);

  if (images.length === 0) {
    console.error("No images in carousel");
    return null;
  }

  const currentImage = images[currentSlide];
  const hasNextImage = currentSlide < images.length - 1;
  const hasPrevImage = currentSlide > 0;
  const hasCopyright = !!currentImage.copyright;

  return (
    <div
      className={classnames(
        classes.carousel_wrapper,
        classes[`carousel_wrapper${hasCopyright ? "_copyright" : ""}`],
      )}
    >
      <div ref={carouselRef} className={classes.carousel}>
        {images.map((image) => (
          <div key={image.src} className={classes.slide}>
            <ImageComponent
              {...image}
              alt={image.alt || imageAltFallback}
              width={906}
              height={513}
            />
          </div>
        ))}
      </div>
      {images.length > 1 && (
        <>
          <div className={classes.controls}>
            <IconButton
              disabled={!hasPrevImage}
              onClick={prev}
              className={classes.button_left}
              icon="arrowLeftThin"
              title={t("previous")}
            />
            <IconButton
              disabled={!hasNextImage}
              onClick={next}
              className={classes.button_right}
              icon="arrowRightThin"
              title={t("next")}
            />
          </div>
          <div className={classes.counter}>
            {currentSlide + 1}/{images.length}
          </div>
        </>
      )}
    </div>
  );
};

export const ImageCarouselType = {
  images: arrayOf(shape(ImageType)),
  imageAltFallback: string,
};

ImageCarousel.propTypes = ImageCarouselType;

export default ImageCarousel;
